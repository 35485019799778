<template>
  <div class="uk-container uk-form-width-large">
    <vue-headful
      :title="`${isLite ? 'handleLite' : 'handle.fi'} | borrow fxTokens`"
      description="deposit collateral &amp; borrow fxTokens"
      image="src/assets/logo.png"
    />
    <BorrowComponent />
  </div>
</template>

<script>
import { store } from "@/store";
import BorrowComponent from "@/components/BorrowComponent";

export default {
  name: "Borrow",
  components: {
    BorrowComponent,
  },
  computed: {
    isLite() {
      return store.state.isLite;
    },
    account() {
      return store.state.account;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/styles/handle.fi" as handle;
</style>
